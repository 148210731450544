var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-spin",
    { attrs: { spinning: _vm.spinning } },
    [
      _c(
        "a-form",
        {
          staticClass: "adherent_form",
          attrs: {
            form: _vm.form,
            layout: _vm.formLayout,
            id: "add_form_form",
            name: "add_form_form"
          }
        },
        [
          _c("h1", { staticClass: "title" }, [_vm._v("Paramètres :")]),
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { attrs: { xs: 22, md: 12 } },
                [
                  _c(
                    "a-form-item",
                    _vm._b(
                      { attrs: { label: "Logo:" } },
                      "a-form-item",
                      _vm.formItemLayout,
                      false
                    ),
                    [
                      _c(
                        "a-upload",
                        {
                          staticClass: "avatar-uploader",
                          attrs: {
                            action: _vm.uploadUrl(),
                            beforeUpload: _vm.beforeUpload,
                            headers: {
                              Authorization:
                                "Bearer " + _vm.getAuthorizationToken()
                            },
                            multiple: false,
                            showUploadList: false,
                            listType: "picture-card",
                            name: "file"
                          },
                          on: { change: _vm.handleChange }
                        },
                        [
                          _vm.organisation.file_name
                            ? _c("img", {
                                staticClass: "profile_pic",
                                attrs: {
                                  src: _vm.organisation.file_url,
                                  alt: "avatar"
                                }
                              })
                            : _c(
                                "div",
                                [
                                  _c("a-icon", {
                                    attrs: {
                                      type: _vm.loading ? "loading" : "plus"
                                    }
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "ant-upload-text" },
                                    [_vm._v("Joindre")]
                                  )
                                ],
                                1
                              )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    _vm._b(
                      { attrs: { label: "Code:" } },
                      "a-form-item",
                      _vm.formItemLayout,
                      false
                    ),
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "code",
                              {
                                rules: [
                                  {
                                    required: false,
                                    message: "Code est obligatoire!"
                                  }
                                ]
                              }
                            ],
                            expression:
                              "[\n              'code',\n              {\n                rules: [\n                  {\n                    required: false,\n                    message: 'Code est obligatoire!',\n                  },\n                ],\n              },\n            ]"
                          }
                        ]
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    _vm._b(
                      { attrs: { label: "Raison sociale:" } },
                      "a-form-item",
                      _vm.formItemLayout,
                      false
                    ),
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "nom_fr",
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: "Nom  est obligatoire!"
                                  }
                                ]
                              }
                            ],
                            expression:
                              "[\n              'nom_fr',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Nom  est obligatoire!',\n                  },\n                ],\n              },\n            ]"
                          }
                        ]
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    _vm._b(
                      { attrs: { label: "Raison sociale [Ar]:" } },
                      "a-form-item",
                      _vm.formItemLayout,
                      false
                    ),
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "nom_ar",
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: "Nom arabe est obligatoire!"
                                  }
                                ]
                              }
                            ],
                            expression:
                              "[\n              'nom_ar',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Nom arabe est obligatoire!',\n                  },\n                ],\n              },\n            ]"
                          }
                        ]
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    _vm._b(
                      { attrs: { label: "Siege wilaya" } },
                      "a-form-item",
                      _vm.formItemLayout,
                      false
                    ),
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "siege_wilaya_id",
                                {
                                  rules: [
                                    {
                                      required: true,
                                      message: "Siege wilaya  est obligatoire!"
                                    },
                                    {
                                      validator:
                                        _vm.wilayaDeRegionDefaultValueIsnotPermited
                                    }
                                  ]
                                }
                              ],
                              expression:
                                "[\n              'siege_wilaya_id',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Siege wilaya  est obligatoire!',\n                  },\n                  {\n                    validator: wilayaDeRegionDefaultValueIsnotPermited,\n                  },\n                ],\n              },\n            ]"
                            }
                          ],
                          attrs: { placeholder: "Choisir  une wilaya" },
                          on: { change: _vm.handleWilayasChange }
                        },
                        [
                          _c("a-select-option", { key: 0 }, [
                            _vm._v("Choisir une wilaya")
                          ]),
                          _vm._l(_vm.listWilayas, function(wilaya) {
                            return _c("a-select-option", { key: wilaya.id }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(wilaya.nom_fr) +
                                  "\n            "
                              )
                            ])
                          })
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    _vm._b(
                      { attrs: { label: "Siege commune" } },
                      "a-form-item",
                      _vm.formItemLayout,
                      false
                    ),
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "siege_commune_id",
                                {
                                  rules: [
                                    {
                                      required: true,
                                      message: "Siege commune  est obligatoire!"
                                    },
                                    {
                                      validator:
                                        _vm.communeDeRegionDefaultValueIsnotPermited
                                    }
                                  ]
                                }
                              ],
                              expression:
                                "[\n              'siege_commune_id',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Siege commune  est obligatoire!',\n                  },\n                  {\n                    validator: communeDeRegionDefaultValueIsnotPermited,\n                  },\n                ],\n              },\n            ]"
                            }
                          ],
                          attrs: { placeholder: "Choisir  une commune" }
                        },
                        [
                          _c("a-select-option", { key: 0 }, [
                            _vm._v("Choisir une commune")
                          ]),
                          _vm._l(_vm.local_communes, function(commune) {
                            return _c("a-select-option", { key: commune.id }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(commune.nom_fr) +
                                  "\n            "
                              )
                            ])
                          })
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    _vm._b(
                      { attrs: { label: "Date création" } },
                      "a-form-item",
                      _vm.formItemLayout,
                      false
                    ),
                    [
                      _c("a-date-picker", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "dt_creation",
                              {
                                rules: [
                                  {
                                    type: "object",
                                    required: true,
                                    message:
                                      "Date  de création est obligatoire!"
                                  }
                                ]
                              }
                            ],
                            expression:
                              "[\n              'dt_creation',\n              {\n                rules: [\n                  {\n                    type: 'object',\n                    required: true,\n                    message: 'Date  de création est obligatoire!',\n                  },\n                ],\n              },\n            ]"
                          }
                        ],
                        staticStyle: { width: "100%" },
                        attrs: {
                          disabled: _vm.isCreationDateDisabled,
                          format: _vm.dateFormat
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    _vm._b(
                      { attrs: { label: "Les dates de paiement" } },
                      "a-form-item",
                      _vm.formItemLayout,
                      false
                    ),
                    [
                      _c("a-range-picker", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: ["dt_paiement"],
                            expression: "['dt_paiement']"
                          }
                        ],
                        staticStyle: { width: "100%" }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    _vm._b(
                      { attrs: { label: "ID du store Aderial" } },
                      "a-form-item",
                      _vm.formItemLayout,
                      false
                    ),
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: ["aderial_id"],
                            expression: "['aderial_id']"
                          }
                        ]
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    _vm._b(
                      { attrs: { label: "Clé store Aderial" } },
                      "a-form-item",
                      _vm.formItemLayout,
                      false
                    ),
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: ["aderial_key"],
                            expression: "['aderial_key']"
                          }
                        ]
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    _vm._b(
                      { attrs: { label: "UUID store Aderial" } },
                      "a-form-item",
                      _vm.formItemLayout,
                      false
                    ),
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: ["aderial_uuid"],
                            expression: "['aderial_uuid']"
                          }
                        ]
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { xs: 22, md: 12 } },
                [
                  _c(
                    "a-form-item",
                    _vm._b(
                      { attrs: { label: "Image d'accueil :" } },
                      "a-form-item",
                      _vm.formItemLayout,
                      false
                    ),
                    [
                      _c(
                        "a-upload",
                        {
                          staticClass: "avatar-uploader",
                          attrs: {
                            action: _vm.uploadUrl(),
                            beforeUpload: _vm.beforeUpload,
                            headers: {
                              Authorization:
                                "Bearer " + _vm.getAuthorizationToken()
                            },
                            multiple: false,
                            showUploadList: false,
                            listType: "picture-card",
                            name: "login_bg_file"
                          },
                          on: { change: _vm.handleChange }
                        },
                        [
                          _vm.organisation.login_bg_file_name
                            ? _c("img", {
                                staticClass: "profile_pic",
                                attrs: {
                                  src: _vm.organisation.login_bg_file_url,
                                  alt: "avatar"
                                }
                              })
                            : _c(
                                "div",
                                [
                                  _c("a-icon", {
                                    attrs: {
                                      type: _vm.loading ? "loading" : "plus"
                                    }
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "ant-upload-text" },
                                    [_vm._v("Joindre")]
                                  )
                                ],
                                1
                              )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    _vm._b(
                      { attrs: { label: "Adresse:" } },
                      "a-form-item",
                      _vm.formItemLayout,
                      false
                    ),
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "adresse",
                              {
                                rules: [
                                  {
                                    required: false,
                                    message: "Adresse  est obligatoire!"
                                  }
                                ]
                              }
                            ],
                            expression:
                              "[\n              'adresse',\n              {\n                rules: [\n                  {\n                    required: false,\n                    message: 'Adresse  est obligatoire!',\n                  },\n                ],\n              },\n            ]"
                          }
                        ]
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    _vm._b(
                      { attrs: { label: "Longitude :" } },
                      "a-form-item",
                      _vm.formItemLayout,
                      false
                    ),
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "longitude",
                              {
                                rules: [
                                  {
                                    required: false,
                                    message: "Longitude  est obligatoire!"
                                  }
                                ]
                              }
                            ],
                            expression:
                              "[\n              'longitude',\n              {\n                rules: [\n                  {\n                    required: false,\n                    message: 'Longitude  est obligatoire!',\n                  },\n                ],\n              },\n            ]"
                          }
                        ]
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    _vm._b(
                      { attrs: { label: "Latitude:" } },
                      "a-form-item",
                      _vm.formItemLayout,
                      false
                    ),
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "latitude",
                              {
                                rules: [
                                  {
                                    required: false,
                                    message: "Latitude  est obligatoire!"
                                  }
                                ]
                              }
                            ],
                            expression:
                              "[\n              'latitude',\n              {\n                rules: [\n                  {\n                    required: false,\n                    message: 'Latitude  est obligatoire!',\n                  },\n                ],\n              },\n            ]"
                          }
                        ]
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    _vm._b(
                      { attrs: { label: "Tél.:" } },
                      "a-form-item",
                      _vm.formItemLayout,
                      false
                    ),
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "tel_mobile",
                              {
                                rules: [
                                  {
                                    required: false,
                                    message: "Tél  est obligatoire!"
                                  }
                                ]
                              }
                            ],
                            expression:
                              "[\n              'tel_mobile',\n              {\n                rules: [\n                  {\n                    required: false,\n                    message: 'Tél  est obligatoire!',\n                  },\n                ],\n              },\n            ]"
                          }
                        ]
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    _vm._b(
                      { attrs: { label: "Tél. Fixe:" } },
                      "a-form-item",
                      _vm.formItemLayout,
                      false
                    ),
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "tel_fixe",
                              {
                                rules: [
                                  {
                                    required: false,
                                    message: "Tél  est obligatoire!"
                                  }
                                ]
                              }
                            ],
                            expression:
                              "[\n              'tel_fixe',\n              {\n                rules: [\n                  {\n                    required: false,\n                    message: 'Tél  est obligatoire!',\n                  },\n                ],\n              },\n            ]"
                          }
                        ]
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    _vm._b(
                      { attrs: { label: "Fax:" } },
                      "a-form-item",
                      _vm.formItemLayout,
                      false
                    ),
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "tel_fax",
                              {
                                rules: [
                                  {
                                    required: false,
                                    message: "Fax  est obligatoire!"
                                  }
                                ]
                              }
                            ],
                            expression:
                              "[\n              'tel_fax',\n              {\n                rules: [\n                  {\n                    required: false,\n                    message: 'Fax  est obligatoire!',\n                  },\n                ],\n              },\n            ]"
                          }
                        ]
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    _vm._b(
                      { attrs: { label: "Email:" } },
                      "a-form-item",
                      _vm.formItemLayout,
                      false
                    ),
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "email",
                              {
                                rules: [
                                  {
                                    required: false,
                                    message: "Email  est obligatoire!"
                                  },
                                  {
                                    type: "email",
                                    message: "E-mail! n'est pas valide "
                                  }
                                ]
                              }
                            ],
                            expression:
                              "[\n              'email',\n              {\n                rules: [\n                  {\n                    required: false,\n                    message: 'Email  est obligatoire!',\n                  },\n                  {\n                    type: 'email',\n                    message: 'E-mail! n\\'est pas valide ',\n                  },\n                ],\n              },\n            ]"
                          }
                        ]
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    _vm._b(
                      { attrs: { label: "CCP:" } },
                      "a-form-item",
                      _vm.formItemLayout,
                      false
                    ),
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "ccp",
                              {
                                rules: [
                                  {
                                    required: false,
                                    message: "CCP  est obligatoire!"
                                  }
                                ]
                              }
                            ],
                            expression:
                              "[\n              'ccp',\n              {\n                rules: [\n                  {\n                    required: false,\n                    message: 'CCP  est obligatoire!',\n                  },\n                ],\n              },\n            ]"
                          }
                        ]
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    _vm._b(
                      { attrs: { label: "Compte bancaire:" } },
                      "a-form-item",
                      _vm.formItemLayout,
                      false
                    ),
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "compte_bancaire",
                              {
                                rules: [
                                  {
                                    required: false,
                                    message: "Compte bancaire  est obligatoire!"
                                  }
                                ]
                              }
                            ],
                            expression:
                              "[\n              'compte_bancaire',\n              {\n                rules: [\n                  {\n                    required: false,\n                    message: 'Compte bancaire  est obligatoire!',\n                  },\n                ],\n              },\n            ]"
                          }
                        ]
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    _vm._b(
                      {
                        attrs: {
                          label: "Paiement collectif [Cabinet de groupe]:"
                        }
                      },
                      "a-form-item",
                      {
                        labelCol: {
                          xs: { span: 12 },
                          sm: { span: 12 }
                        },
                        wrapperCol: {
                          xs: { span: 10 },
                          sm: { span: 10 }
                        }
                      },
                      false
                    ),
                    [
                      _c("a-switch", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "is_cabinet_group_paiement_collectif",
                              {
                                rules: [
                                  {
                                    required: false,
                                    message: "Ce champ est bligatoire!"
                                  }
                                ]
                              }
                            ],
                            expression:
                              "[\n              'is_cabinet_group_paiement_collectif',\n              {\n                rules: [\n                  {\n                    required: false,\n                    message: 'Ce champ est bligatoire!',\n                  },\n                ],\n              },\n            ]"
                          }
                        ],
                        attrs: {
                          checked:
                            _vm.organisation.is_cabinet_group_paiement_collectif
                        },
                        on: { change: _vm.onCabinetGroupToggle }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { staticClass: "mt-2" },
            [
              _c(
                "a-col",
                { attrs: { offset: 2, span: 3 } },
                [
                  _c(
                    "a-form-item",
                    _vm._b({}, "a-form-item", _vm.tailFormItemLayout, false),
                    [
                      _c(
                        "a-button",
                        {
                          attrs: {
                            loading: _vm.processing,
                            htmlType: "submit",
                            type: "primary"
                          },
                          on: { click: _vm.handleFormSubmission }
                        },
                        [_vm._v("Sauvergarder\n            ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { offset: 1, span: 3 } },
                [
                  _c(
                    "a-form-item",
                    _vm._b({}, "a-form-item", _vm.tailFormItemLayout, false),
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "default" },
                          on: { click: _vm.handleCancel }
                        },
                        [_vm._v("Annuler")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { offset: 1, span: 3 } },
                [
                  _c(
                    "a-spin",
                    {
                      attrs: { spinning: _vm.updatingUsersReglementprocessing }
                    },
                    [
                      _c(
                        "a-form-item",
                        _vm._b(
                          {},
                          "a-form-item",
                          _vm.tailFormItemLayout,
                          false
                        ),
                        [
                          _c(
                            "a-button",
                            {
                              attrs: { type: "default" },
                              on: { click: _vm.updateUsersReglement }
                            },
                            [_vm._v("Mettre à jour les régléments")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }